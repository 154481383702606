import { createBrowserRouter } from "react-router-dom";
import HomePage from "../Pages/HomePage/HomePage";
import App from "../App";
// import AboutPage from "../Pages/AboutPage/AboutPage";
// import ServicePage from "../Pages/ServicePage/ServicePage";
// import PortfolioPage from "../Pages/PortfolioPage/PortfolioPage";
// import BlogPage from "../Pages/BlogPage/BlogPage";
// import ContactPage from "../Pages/ContactPage/ContactPage";
// import SoftwarePage from "../Pages/SoftwarePage/SoftwarePage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
          path: "", element: (
            <HomePage />
          )
      },
      // {
      //   path: "about", element: (
      //     <AboutPage />
      //   )
      // },
      // {
      //   path: "service", element: (
      //     <ServicePage />
      //   )
      // },
      // {
      //   path: "portfolio", element: (
      //     <PortfolioPage />
      //   )
      // },
      // {
      //   path: "blog", element: (
      //     <BlogPage />
      //   )
      // },
      // {
      //   path: "contact", element: (
      //     <ContactPage />
      //   )
      // },
      // { 
      //   path: "software", element: (
      //     <SoftwarePage />
      //   )
      // }
    ]
  }
]);
