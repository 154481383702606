import React, { useState } from 'react'
import { theme } from '../../Utility/Theme';


const ColorTheme = () => {
    const [showTool,] = useState<boolean>(false);
    const [isActive, setIsActive] = useState<boolean>(false);
    const [selectedColor, setSelectedColor] = useState<number>(6);
    const [isDarkMode, setIsDarkMode] = useState<boolean>(false);

    const toggleLightMode = () => {
        setIsDarkMode(false);
    };

    const toggleTool = () => {
        setIsActive(!isActive);
    };

    const selectColor = (index: number) => {
        setSelectedColor(index);
    };

    const toggleDarkMode = () => {
        setIsDarkMode(true);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault()
    }

    return (
        <>
            {showTool && 
                <div className={`zx-tool zx-zx-tool ${isActive ? "zx-tool-active" : ""}`}>
                    <div className="zx-tool-btn">
                        <button onClick={toggleTool} type="button" className="btn-zx-tool result-placeholder">
                            <i className="fa fa-cog fa-spin" aria-hidden="true"></i>
                        </button>
                        <div className="color-variant">
                            <div className="heading">
                                <h2>Select Color</h2>
                            </div>
                            <ul className="zx-color">
                                {
                                    theme.map((data, index) => (
                                        <li key={index} onClick={() => selectColor(index + 1)} className={` ${data.color}  ${selectedColor === index + 1 ? "active-variant" : ""}`}>
                                            <a onClick={handleSubmit} href="/" aria-label='Link to our color page' className="zx-all-color"></a>
                                        </li>
                                    ))
                                }
                            </ul>
                            <div className="heading">
                                <h2>Dark mode</h2>
                            </div>
                            <ul className="dark-mode">
                                <li className="dark" onClick={toggleDarkMode} style={{ display: isDarkMode ? "none" : "block" }}>
                                    <img src="assets/img/tools/moon.png" alt="moon" />
                                </li>
                                <li className="white" onClick={toggleLightMode} style={{ display: isDarkMode ? "block" : "none" }}>
                                    <img src="assets/img/tools/sun.png" alt="sun" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            }
            {isDarkMode && (
                <link rel="stylesheet" className="dark-link-mode" href="assets/css/dark.css" />
            )}
            {selectedColor && (
                <link rel="stylesheet" href={`assets/css/color-${selectedColor}.css`} />
            )}
        </>
    )
}

export default ColorTheme