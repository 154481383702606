import React from 'react';
import './HomePage.scss';
import Banner from '../../Components/Banner/Banner';
import Work from '../../Components/Work/Work';
//import Benefits from '../../Components/Benefits/Benefits';
//import Pricing from '../../Components/Pricing/Pricing';
import Faq from '../../Components/Faq/Faq';
//import AppTestimonial from '../../Components/AppTestimonial/AppTestimonial';
import BestServices from '../../Components/BestServices/BestServices';

const HomePage = () => {
  return (
    <>
        <Banner />
        <BestServices />
        <Work />
        {/*<Benefits />*/}
        {/*<AppTestimonial />
        <Pricing />*/}
        <Faq />

    </>
  )
}

export default HomePage