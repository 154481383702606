export const header = [
    // {
    //     href: "/about",
    //     title: "About"
    // }, 
    // {
    //     href: "/service",
    //     title: "Services"
    // },


    // {
    //     href: "/portfolio",
    //     title: "Portfolio"
    // },

    // {
    //     href: "/blog",
    //     title: "Blog"
    // },
    // {
    //     href: "/contact",
    //     title: "Contact Us"
    // },
    {
        href: "#features",
        title: "Features"
    },
    {
        href: "#experience",
        title: "Experience"
    },
    {
        href: "#faq",
        title: "F.A.Q."
    },
    {
        href: "#contact",
        title: "Contact Us"
    }
]