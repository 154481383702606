import React from 'react'

const HeaderButton = () => {
  return (
    <div className='d-none d-lg-block'>
      &nbsp;
      {/* <div className="d-flex d-991">
          <div className="mt-25">
              <button type="button" className="zx-buttons result-placeholder">Try It Free</button>
          </div>
      </div> */}
    </div>
  )
}

export default HeaderButton;