import React from 'react';
import './BestServices.scss';
import { bestServices } from '../../Utility/BestServices';
import { Fade } from 'react-awesome-reveal';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";

const BestServices = () => {
  return (
    <section className="section-services padding-tb-100 bg-market" id="features">
            <div className="container">
                <div className="row">
                    <Fade triggerOnce duration={1000} direction='up' className="col-lg-6 col-sm-12">
                        <>
                            <div className="zx-app-banner">
                                <h4>ABOUT</h4>
                                <h2>Our work</h2>
                            </div>
                            <div className="container-fulid">
                                <div className="row">
                                    {
                                        bestServices.map((data, index) => (
                                            <div key={index} className="col-md-6 col-sm-12 zx-app-zorox">
                                                <div className="zx-app-accouts">
                                                    <h5>{data.number}</h5>
                                                    <h4>{data.title}</h4>
                                                    <div>
                                                        {data.description}
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>
                            </div>
                        </>
                    </Fade>
                    <Fade triggerOnce duration={1500} direction='up' className="col-lg-6 col-sm-12">
                        <Swiper
                            pagination={{
                                clickable: true
                            }}
                            modules={[Pagination, Autoplay]}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            loop={true}
                            spaceBetween={50}
                            slidesPerView={1}
                            className="swiper p-10 lp-swip-services"
                        >

                            <div className="swiper-wrapper">
                                <SwiperSlide className="swiper-slide">
                                    <img src="assets/img/landing/featured1/1.png" alt="" className="mobaile-viwe" />
                                </SwiperSlide>
                                <SwiperSlide className="swiper-slide">
                                    <img src="assets/img/landing/featured1/2.png" alt="" className="mobaile-viwe" />
                                </SwiperSlide>
                                <SwiperSlide className="swiper-slide">
                                    <img src="assets/img/landing/featured1/3.png" alt="" className="mobaile-viwe" />
                                </SwiperSlide>                               
                            </div>
                            <div className="pagipagination-services">
                                <div className="swiper-pagination"></div>
                            </div>
                        </Swiper>
                    </Fade>
                    <Fade triggerOnce duration={1500} direction='up' className="col-lg-6 col-sm-12 d-none d-lg-block">
                        <div>
                            <img src="assets/img/theme/plus.png" alt="plus" className="plus-effcat-1" />
                        </div>
                    </Fade>
                </div>
            </div>
        </section>
  )
}

export default BestServices;