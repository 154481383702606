import React from 'react';
import './App.scss';
import { Outlet } from "react-router";
import ReactGA from 'react-ga4';
import FooterTwo from './Layouts/FooterTwo';
import Header from './Layouts/Header';
//import Footer from './Layouts/Footer';

function App() {
  ReactGA.initialize('G-N54YCFKGCZ');
  return (
    <>
      <div>
        <Header />
        <Outlet />
        {/*<Footer />*/}
        <FooterTwo />
      </div>
    </>
  );
}
export default App;
